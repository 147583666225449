import {
  Flex,
  Text,
  Button,
  Box,
  Heading,
  Image,
  Card,
  CardBody,
  CardHeader,
  SimpleGrid,
  Stack,
  Container,
  Divider,
  Spinner,
  Link,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useEffect } from "react";

import dynamic from "next/dynamic";
import PolandModal from "../country-modals/poland-modal";
import { Section } from "../section";
import SpinnerFull from "../spinner-full";
import { siteConfig } from "../../configuration/config";
import FeatureCheckList from "../feature-check-list";
import { Features } from "../features";
import FeaturePresentation from "../feature-presentation";
import LandingFaq from "../landing-faq";
import PricingSwitch from "../pricing-switch";
import { Product } from "../../pages/api/plans";
import { useLocalStorage } from "../../hooks/use-local-storage";
import { useLocationDetector } from "../../hooks/use-location-detector";
import { useSessionStorage } from "../../hooks/use-session-storage";
import { lowIncomeHighPotentialCountryCodes } from "../../configuration/promotion/discount-locations";
import { logger } from "../../utils/logger";
import { billingSelectionKey, promotionCodeIdKey } from "../../pages/billing";
import { promotionCodes } from "../../configuration/promotion/promotion-codes";
import { LandingLocale } from "../../localization/landing";
import AvatarList from "./avatar-list";
import TestimonialList from "./testimonials";
import { IoOpenOutline } from "react-icons/io5";
import SenjaTestimonialsSmall from "../senja-testimonials-small";

const LanguageSelector = dynamic(() => import("../language-selector-landing"), {
  ssr: false,
  loading: () => <Spinner />,
});

const YoutubeEmbed = dynamic(() => import("../youtube-embed"), {
  ssr: false,
  loading: () => <Spinner />,
});

const SenjaTestimonials = dynamic(() => import("../senja-testimonials"), {
  ssr: false,
  loading: () => <Spinner />,
});

export interface LandingPageProps {
  products: Product[] | null;
  baseLangCode: string;
  l: LandingLocale;
}

export default function LandingPage({ products, l }: LandingPageProps) {
  const router = useRouter();
  const { setStorageData: setBillingSelection } =
    useLocalStorage<string>(billingSelectionKey);

  const ipLocation = useLocationDetector({
    disable: !siteConfig.isDefault,
  });

  const {
    storageData: promotionCodeId,
    setStorageData: setPromotionCodeId,
    isLoadingStorage: isLoadingPromotionCodeId,
  } = useSessionStorage<string>(promotionCodeIdKey);

  useEffect(() => {
    if (isLoadingPromotionCodeId) {
      return;
    }

    if (
      ipLocation?.countryCode &&
      lowIncomeHighPotentialCountryCodes.includes(ipLocation.countryCode)
    ) {
      logger.info(`Setting promotion code for ${ipLocation.country}`);
      setPromotionCodeId(promotionCodes[0].code);
    }
  }, [isLoadingPromotionCodeId, ipLocation, setPromotionCodeId]);

  const onSignupClick = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    logger.info("On Signup");
    router.push({
      pathname: "/authentication/signup",
      query: { forwardSignup: true },
    });
  };

  useEffect(() => {
    if (router && siteConfig.overrideLanding) {
      router.push("/authentication/signin");
    }
  }, [router]);

  useEffect(() => {}, []);

  if (siteConfig.overrideLanding) {
    return <SpinnerFull />;
  }

  return (
    <Box>
      <PolandModal />
      <Section>
        <Flex flexDir="column" flex="1">
          <Heading
            as="h1"
            color={"brand.gray"}
            fontSize={{ base: "6xl", lg: "38px", xl: "6xl" }}
            fontWeight="bold"
            lineHeight={{ base: "4rem", lg: "2.5rem", xl: "4rem" }}
            letterSpacing={".001rem"}
            mt={{ base: "30px", xl: "70px" }}
          >
            {l["landing.title1"]}{" "}
            <Box
              as="span"
              bgGradient={"linear-gradient(to right, #f6d78b, #7f64b0)"}
              backgroundClip="text"
            >
              {l["landing.title2"]}
            </Box>{" "}
            <Box as="span">{l["landing.title3"]}</Box>
          </Heading>
          <Text mt={5}>{l["landing.subtitle1"]}</Text>

          <Box id="feature-checklist" display="none">
            <FeatureCheckList l={l} />
          </Box>
          <Box id="top-testimonials" my={9}>
            <SenjaTestimonialsSmall />
          </Box>

          <Button
            colorScheme={"brand.primary"}
            color={"brand.gray"}
            maxW={{ base: "100%", sm: "300px" }}
            onClick={onSignupClick}
          >
            {l["landing.button.freeTrial"]}
          </Button>
          <Text
            maxW={{ base: "100%", sm: "300px" }}
            textAlign="center"
            fontSize="xs"
            mt={1}
          >
            {l["landing.creditCardNotice"]}
          </Text>
        </Flex>
        <Flex
          display={{ base: "none", lg: "block" }}
          flex="1"
          justifyContent={{ base: "center" }}
        >
          <Image
            src="/landing-1.jpeg"
            alt={l["landing.imageAlt.landing"]}
            alignSelf={"center"}
            width={{ base: "250px", sm: "350px", md: "450px", xl: "100%" }}
          />
        </Flex>
      </Section>

      <Box
        backgroundColor="brand.gray.100"
        color="brand.gray.600"
        textAlign={"center"}
        p={7}
        my={7}
        fontSize={"sm"}
      >
        <Flex justifyContent={"center"} alignItems="center">
          <Image
            src="/vector/laurel-left.svg"
            alt={l["landing.imageAlt.laurelLeft"]}
            w="50px"
            mr={3}
            opacity={0.3}
          />
          <Box>
            <Text fontSize={{ base: "2xs", sm: "xs", md: "md" }}>
              {l["landing.text.voiceControlTeam"]}
            </Text>

            <Box fontSize={"lg"}>
              <Text fontWeight={"bold"}>{l["landing.text.trustedBy"]}</Text>
            </Box>
          </Box>
          <Image
            src="/vector/laurel-right.svg"
            alt={l["landing.imageAlt.laurelRight"]}
            w="50px"
            ml={3}
            opacity={0.3}
          />
        </Flex>
      </Box>

      <Section>
        <SimpleGrid
          spacing={4}
          templateColumns={{
            base: "repeat(auto-fill, minmax(100%, 1fr))",
            lg: "repeat(auto-fill, minmax(30%, 1fr))",
          }}
          width={"100%"}
        >
          <Card borderTop={"5px solid"} borderColor="brand.primary.200">
            <CardHeader>
              <Heading fontSize="xl" lineHeight="1">
                {l["landing.card.title.advancedAI"]}
              </Heading>
            </CardHeader>
            <CardBody>
              <Text>{l["landing.card.text.advancedAI"]}</Text>
            </CardBody>
          </Card>
          <Card borderTop={"5px solid"} borderColor="brand.secondary.600">
            <CardHeader>
              <Heading fontSize="xl" lineHeight="1">
                {l["landing.card.title.multipleLanguages"]}
              </Heading>
            </CardHeader>
            <CardBody>
              <Text>{l["landing.card.text.multipleLanguages"]}</Text>
              <Box mt="5">
                <LanguageSelector />
              </Box>
            </CardBody>
          </Card>
          <Card borderTop={"5px solid"} borderColor="brand.green.400">
            <CardHeader>
              <Heading fontSize="xl" lineHeight="1">
                {l["landing.card.title.premiumVoice"]}
              </Heading>
            </CardHeader>
            <CardBody>
              <Text>{l["landing.card.text.premiumVoice"]}</Text>
            </CardBody>
          </Card>
        </SimpleGrid>
      </Section>

      <Box>
        <Heading
          as="h3"
          textAlign={"center"}
          mt={10}
          mb={6}
          mx={2}
          fontWeight="thin"
          fontSize={"5xl"}
          lineHeight={"1"}
        >
          {l["landing.sectionTitle.meetTutors"]}
        </Heading>
        <Text textAlign={"center"} mb={12}>
          {l["landing.sectionText.moreThanTutors"]}
        </Text>
        <Box
          w="100%"
          overflowX={{ base: "auto", md: "hidden" }}
          bgGradient={
            "linear-gradient(to right, brand.blue.600, brand.blue.800)"
          }
        >
          <Flex
            justifyContent={{ base: "flex-start", md: "space-around" }}
            pt={10}
            pb={5}
          >
            <AvatarList l={l} />
          </Flex>
          <Text
            textAlign={"center"}
            mb={3}
            fontSize="xs"
            fontWeight={"bold"}
            fontStyle="italic"
            color="white"
          >
            {l["landing.text.andManyMore"]}
          </Text>
        </Box>
      </Box>

      <Box backgroundColor="white" py="12">
        <Section>
          <Flex
            alignItems={"center"}
            justifyContent="center"
            flexDir={{ base: "column", lg: "row" }}
          >
            <Flex flex="1" w="full" maxW="600px">
              <YoutubeEmbed embedId="1zacrXpoXiA" />
            </Flex>
            <Flex pl={{ lg: 12 }} flexDir={"column"} flex="1">
              <Heading
                color={"brand.gray"}
                size={{ base: "md", md: "lg" }}
                fontWeight="bold"
                mt={{ base: 4, lg: 0 }}
              >
                {l["landing.text.learnWithAITutors"]}
              </Heading>
              <Text mt={{ base: 0, md: 3 }}>
                {l["landing.text.wonderedAboutTutor"]}
              </Text>
              <Text mt="4">{l["landing.text.rememberLanguageLearning"]}</Text>
            </Flex>
          </Flex>
        </Section>
      </Box>

      <Box id="feature-v1" backgroundColor="gray.100">
        <Features l={l} />
        <Divider />
      </Box>

      <Box id="feature-v2" display={"none"} backgroundColor="white">
        <Box pt={5} mt={5}>
          <Heading
            as="h3"
            textAlign={"center"}
            mt={10}
            mb={6}
            mx={2}
            fontWeight="thin"
            fontSize={"5xl"}
            lineHeight={"1"}
          >
            {l["landing.sectionTitle.featureSpotlight"]}
          </Heading>
          <Text textAlign={"center"} mb={12} px="5">
            {l["landing.sectionText.featureSpotlight"]}
          </Text>
        </Box>

        <Divider maxW="1000px" m="0 auto" />

        <FeaturePresentation
          l={l}
          onButtonClick={() => {
            const product = products?.[0];

            if (product) {
              setBillingSelection(product.id);
            }

            onSignupClick();
          }}
        />
      </Box>

      <Section>
        <Flex flexDir={"column"} flex="1">
          <Heading
            color={"brand.gray"}
            fontSize={{ base: "3xl", md: "5xl" }}
            fontWeight="bold"
            lineHeight={1}
            mt={{ base: "50px", lg: "100px" }}
          >
            {l["landing.text.safeEnvironment"]}
          </Heading>
          <Text mt="4" mb={{ base: 12, md: 0 }}>
            {l["landing.text.supportiveEnvironment"]}
          </Text>
        </Flex>
        <Flex flex="1" display={{ base: "none", md: "block" }}>
          <Image
            src="/landing-2.jpeg"
            alt={l["landing.imageAlt.speakingWithAI"]}
            height={"auto"}
            alignSelf={"center"}
            width={{ base: "250px", sm: "350px", md: "450px", lg: "100%" }}
          />
        </Flex>
      </Section>

      <Box backgroundColor="gray.100">
        <Section>
          <Flex w="100%" flexDir={"column"}>
            <Heading
              as="h3"
              textAlign={"center"}
              mt={10}
              mb={6}
              mx={2}
              fontWeight="thin"
              fontSize={"5xl"}
              lineHeight={"1"}
            >
              {l["landing.sectionTitle.insights"]}
            </Heading>
            <Text textAlign={"center"} mb={12}>
              {l["landing.sectionText.realStories"]}
            </Text>
            <SenjaTestimonials />

            <Box
              display="none"
              maxW="600px"
              m="0 auto"
              mb="3"
              id="legacy-testimonials"
            >
              <TestimonialList l={l} />
            </Box>
          </Flex>
        </Section>
      </Box>

      <Box py={{ base: "50px", md: "50px" }} id="faq">
        <Section>
          <Flex w="100%" flexDir={{ base: "column", md: "row" }}>
            <Box flex="1">
              <Heading size="sm" fontWeight={"bold"}>
                {l["landing.sectionTitle.faq"]}
              </Heading>
              <Text mt={2} mb={7}>
                {l["landing.sectionText.faq"]}
              </Text>
            </Box>
            <Box flex="1">
              <LandingFaq l={l} />
            </Box>
          </Flex>
        </Section>
      </Box>
      <Box backgroundColor="gray.100">
        <Box as="section">
          <Container py={{ base: "16", md: "24" }}>
            <Stack spacing={{ base: "4", md: "6" }} alignItems="center">
              <Stack spacing="3" textAlign={"center"}>
                <Heading size={{ base: "md", md: "lg" }}>
                  {l["landing.sectionTitle.pricing"]}
                </Heading>
              </Stack>
              <Text
                fontSize={{ base: "md" }}
                color="fg.muted"
                maxW="2xl"
                textAlign={"center"}
                id="pricing"
                mb={8}
              >
                {l["landing.sectionText.unlockPotential"]}
              </Text>
            </Stack>

            {products && (
              <PricingSwitch
                l={l}
                promotionCodeId={promotionCodeId}
                products={products}
                buttonCallback={({ productId }) => {
                  setBillingSelection(productId);
                  onSignupClick();
                }}
              />
            )}

            <Flex
              textAlign={"center"}
              mt={3}
              w="full"
              alignItems={"center"}
              color="brand.gray.500"
              justifyContent={"center"}
            >
              <Text fontSize="md">
                {l["landing.sectionText.offersForPartners"]}
              </Text>
              <Link ml="2" as="a" href="/partners/schools">
                <IoOpenOutline />
              </Link>
            </Flex>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}
